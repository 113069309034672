import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import Typography from "@mui/material/Typography";

export default function Hamilton() {
  return (
    <Project
      title="Hamilton College ISS"
      picture={
        <img
          alt="ss of website"
          className="project-img"
          src={require("../pictures/hamilton.png")}
          width={600}
        />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          This is Hamilton College's official website for its international
          students. For incoming students, it hosts information on visas,
          packing, and orientation. For current students, information on
          academic breaks, traveling, and employment resources.
          <br />
          <br />
          My web dev related tasks are to update information and revamp the ui
          using the College's web editing software. My other tasks are to help
          international students transition into their first year.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink link="https://www.hamilton.edu/offices/international-student-services">
            Live
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
