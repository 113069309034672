import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import Typography from "@mui/material/Typography";
import ReactPlayer from "react-player";

export default function Drawing() {
  return (
    <Project
      title="Drawing Robot"
      picture={
        <ReactPlayer
          controls
          muted
          url="https://youtube.com/shorts/1RE00t6y0yk?feature=share"
        />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          Upload any svg image and watch it get drawn on paper. The machine
          consists of stepper and servo motors, a belt, and a Raspberry Pi Pico.
          It works by converting the svg image into a series of instructions
          that the machine reads and executes.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink
            icon="youtube"
            link="https://youtube.com/shorts/1RE00t6y0yk?feature=share"
          >
            Moose
          </ProjectLink>
          <ProjectLink
            icon="youtube"
            link="https://youtube.com/shorts/9VnRA_6qNEs?feature=share"
          >
            Penguin
          </ProjectLink>
          <ProjectLink
            icon="youtube"
            link="https://youtube.com/shorts/8SJ_wezH55Q?feature=share"
          >
            Frog
          </ProjectLink>
          <ProjectLink
            icon="youtube"
            link="https://youtube.com/shorts/Yl7mM1VOzBQ?feature=sharee"
          >
            Bird
          </ProjectLink>
          <ProjectLink
            icon="youtube"
            link="https://youtube.com/shorts/1k4egkb0Hxw?feature=share"
          >
            Snake
          </ProjectLink>
          <ProjectLink
            icon="github"
            link="https://github.com/brianiscoding/drawing"
          >
            Github
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
