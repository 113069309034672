import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import Typography from "@mui/material/Typography";

export default function Givewithgifted() {
  return (
    <Project
      title="Give With Gifted"
      picture={
        <img
          alt="ss of website"
          className="project-img"
          src={require("../pictures/givewithgifted.png")}
          width={600}
        />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          This shopify app is a widget for stores that allows customers to buy
          and send gifts to recipients digitally. Stores can capture more sales
          through gifts, send branded gift messages, turn recipients into
          customers, and collect more sales data.
          <br />
          <br />
          My tasks were to 1. update the <b>MongoDB</b> database; 2. build a{" "}
          <b>cronjob</b> to send emails daily with <b>Sendgrid</b>; 3. build{" "}
          <b>Rest API</b> endpoints.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink link="https://www.givewithgifted.com/">Live</ProjectLink>
          <ProjectLink link="https://apps.shopify.com/gifted-1">
            Shopify
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
