import { Outlet } from "react-router-dom";

import Bio from "./Bio.jsx";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

export default function Layout() {
  return (
    <Box sx={styles.wrapper}>
      <Stack direction="row" spacing={4} sx={styles.container}>
        <Box sx={styles.left}>
          <Bio />
        </Box>

        <Box sx={styles.right}>
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
}

const styles = {
  wrapper: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },

  container: {
    width: "1400px",
    height: "750px",
  },

  left: {
    width: 1 / 3,
    height: 1,
    border: 0.1,
  },

  right: {
    width: 2 / 3,
  },
};
