import { Link } from "react-router-dom";
import { useState } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LanguageIcon from "@mui/icons-material/Language";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";

export function ProjectLinks({ children }) {
  return (
    <Stack direction="row" sx={styles.links} spacing={2}>
      {children}
    </Stack>
  );
}

export function ProjectLink({ link, icon, children }) {
  const [hover, set_hover] = useState(false);

  const get_icon = () => {
    switch (icon) {
      case "github":
        return <GitHubIcon />;
      case "linkedin":
        return <LinkedInIcon />;
      case "youtube":
        return <YouTubeIcon />;
      default:
        return <LanguageIcon />;
    }
  };

  return (
    <Link
      onMouseEnter={() => set_hover(true)}
      onMouseLeave={() => set_hover(false)}
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      className="link"
    >
      <Stack direction="row" spacing={0.5} sx={styles.link_wrapper}>
        {get_icon()}
        <Typography style={{ fontWeight: hover && "bold" }}>
          {children}
        </Typography>
      </Stack>
    </Link>
  );
}

export function EmailLink({ email, children }) {
  const [hover, set_hover] = useState(false);

  return (
    <a
      href={`mailto:${email}`}
      onMouseEnter={() => set_hover(true)}
      onMouseLeave={() => set_hover(false)}
      className="link"
    >
      <Stack direction="row" spacing={0.5} sx={styles.link_wrapper}>
        <EmailIcon />
        <Typography style={{ fontWeight: hover && "bold" }}>
          {children}
        </Typography>
      </Stack>
    </a>
  );
}

const styles = {
  links: {
    width: 0.9,
    justifyContent: "center",
  },

  link_wrapper: {
    px: "5px",
    py: "2px",
    border: 2,
    borderRadius: "5px",
    // alignItems: "center"
  },

  link: { "&:": { fontWeight: "bold" } },
};
