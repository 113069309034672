import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import Typography from "@mui/material/Typography";

export default function Givewithgifted() {
  return (
    <Project
      title="Stroke Prediction"
      picture={
        <img
          alt="graph"
          className="project-img"
          src={require("../pictures/stroke.png")}
          width={400}
        />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          A 3 person group project where we built 3 different{" "}
          <b>machine learning</b> models with the <b>Scikit-learn</b> library in{" "}
          <b>Python</b> to analyze a stroke dataset. Our goal was to predict the
          presence of stroke based on the individual's health records. Our
          models included linear regression, SVM, random forest, and grid
          search; all achieved roughly 80% accuracy. Additionally, we wrote a
          blog post for our methods, graphs, and in-depth analysis.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink link="https://github.com/brianiscoding/ml-for-stroke">
            Github
          </ProjectLink>
          <ProjectLink link="https://medium.com/@b33t34/ml-final-9a456c5394c5">
            Blog Post
          </ProjectLink>
          <ProjectLink link="https://www.kaggle.com/datasets/fedesoriano/stroke-prediction-dataset">
            Dataset
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
