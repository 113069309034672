const links = [
  {
    type: "web",
    link: "visaq",
    title: "Visaq",
    subtitle: "Jun 2024 / Personal / Website",
  },
  {
    type: "web",
    link: "hamilton-college-iss",
    title: "Hamilton College ISS",
    subtitle: "May 2024 / Commission / College",
  },
  {
    type: "web",
    link: "give-with-gifted",
    title: "Give With Gifted",
    subtitle: "Apr 2024 / Commission / Shopify",
  },
  {
    type: "misc",
    link: "stroke",
    title: "Stroke Prediction",
    subtitle: "Apr 2024 / School / ML Python",
  },
  {
    type: "misc",
    link: "digdug",
    title: "Dig Dug",
    subtitle: "Nov 2023 / School / x86 Asm",
  },
  {
    type: "misc",
    link: "drawing",
    title: "Drawing Robot",
    subtitle: "May 2023 / Personal / Raspberry Pi",
  },
  {
    type: "misc",
    link: "wordle-solver",
    title: "Wordle Solver",
    subtitle: "Nov 2022 / Personal / Python",
  },
];

export default links;
