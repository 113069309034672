import {
  ProjectLinks,
  ProjectLink,
  EmailLink,
} from "../components/ProjectLinks.jsx";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function Bio() {
  return (
    <Stack sx={styles.container}>
      <img
        alt="headshot"
        className="project-img"
        src={require("../pictures/profile.jpeg")}
        width={200}
      />

      <Typography align="justify" sx={styles.text}>
        Hello, I'm <b>Brian Tran</b>. I study CS and Math at Hamilton College.
        Also, I do freelance full-stack work with <b>MongoDB</b>, <b>Express</b>
        , <b>React</b>, <b>Node.js</b>, and <b>AWS</b>. I am looking for SWE
        internships to learn and problem solve.
        <br />
        <br />
        Outside of work, I enjoy rock climbing, skateboarding, swimming, and
        biking.
      </Typography>

      <ProjectLinks>
        <ProjectLink link="https://github.com/brianiscoding" icon="github">
          Github
        </ProjectLink>

        <ProjectLink
          icon="linkedin"
          link="https://www.linkedin.com/in/brian-tran-b33t34/"
        >
          LinkedIn
        </ProjectLink>

        <EmailLink email="b33t34@gmail.com">b33t34@gmail.com</EmailLink>
      </ProjectLinks>
    </Stack>
  );
}

const styles = {
  container: {
    width: 1,
    height: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  text: {
    width: 0.9,
    fontSize: 20,
  },
};
