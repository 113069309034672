import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import Typography from "@mui/material/Typography";
import ReactPlayer from "react-player";

export default function Wordle() {
  return (
    <Project
      title="Wordle Solver"
      picture={
        <ReactPlayer controls muted url="https://youtu.be/GRhzeJUFWZA" />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          A <b>Python</b> script that plays Wordle all day long using the{" "}
          <b>Selenium</b> library.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink
            icon="github"
            link="https://github.com/brianiscoding/worlde-solver"
          >
            Github
          </ProjectLink>
          <ProjectLink icon="youtube" link="https://youtu.be/GRhzeJUFWZA">
            Youtube
          </ProjectLink>
          <ProjectLink link="https://wordleunlimited.org">
            Wordle Unlimted
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
