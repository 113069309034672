import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import ReactPlayer from "react-player";
import Typography from "@mui/material/Typography";

export default function Digdug() {
  return (
    <Project
      title="Dig Dug"
      picture={
        <ReactPlayer controls muted url="https://youtu.be/24E7ZVBooKo" />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          A recreation of the 80s arcade game Dig Dug using <b>DOSBox</b>, an{" "}
          <b>x86</b> emulator with DOS, written in <b>assembly</b>. Due to my
          College's policies, the code must be private.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink icon="youtube" link="https://youtu.be/24E7ZVBooKo">
            Youtube
          </ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
